@media (min-width: 1201px) {
    .aboutHeader {
        font-size: 100px;
    }

    .aboutText {
        font-size: 18px;
    }

    .titleHeader {
        text-align: left;
    }
}

@media (max-width: 1200px) {
    .aboutHeader {
        font-size: 90px;
    }

    .aboutText {
        font-size: 18px;
    }

    .titleHeader {
        text-align: left;
    }
}

@media (max-width: 1000px) {
    .aboutHeader {
        font-size: 75px;
        text-align: center;
    }

    .aboutText {
        font-size: 18px;
        text-align: center;
    }

    .titleHeader {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .aboutHeader {
        font-size: 56px;
    }

    .aboutText {
        font-size: 16px;
    }
}

.aboutHeader {
    color: #fbbc33;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
}


.aboutText {
    color: #151515;
    font-family: 'Raleway', sans-serif;
}

/* .aboutImages {
    display: block;
    margin-left: auto;
    margin-right: auto;
} */

.aboutSections {
    margin-bottom: 50px;
}