.navbar-style {
    background-color: #151515;
}

.linkStyle {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    padding: 12px;
}

.linkStyle:hover {
    text-decoration: none;
    background-color: white;
    color: #151515;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.navbar {
    margin-bottom: 0;
}