@media (min-width: 1201px) {
    .contactsHeader {
        font-size: 100px;
    }

    .contactDetails {
        font-size: 18px;
    }

    .highlightedText {
        font-size: 28px;
    }
}

@media (max-width: 1200px) {
    .contactsHeader {
        font-size: 90px;
    }

    .contactDetails {
        font-size: 18px;
    }

    .highlightedText {
        font-size: 28px;
    }
}

@media (max-width: 1000px) {
    .contactsHeader {
        font-size: 75px;
    }

    .contactDetails {
        font-size: 16px;
    }

    .highlightedText {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .contactsHeader {
        font-size: 50px;
    }

    .contactDetails {
        font-size: 18px;
    }

    .highlightedText {
        font-size: 20px;
    }
}


.contactsHeader {
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
}



.contactDetails {
    font-family: 'Raleway', sans-serif;
    color: #151515;
}

.highlightedText {
    font-weight: bold;
}

.social {
    padding-bottom: 16px;
}

.contactDetHeaders {
    text-transform: uppercase;
    color: #fbbc33;
    margin-bottom: 24px;
}