@media (min-width: 1201px) {
    .homePageHeader {
        font-size: 72px;
    }

    .sectionHeaders {
        font-size: 36px;
        text-align: left;
    }

    .headerNotLinked {
        font-size: 36px;
    }

    .introText {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .homePageHeader {
        font-size: 72px;
    }

    .sectionHeaders {
        font-size: 36px;
        text-align: left;
    }

    .headerNotLinked {
        font-size: 36px;
    }

    .introText {
        font-size: 18px;
    }
}

@media (max-width: 1000px) {
    .homePageHeader {
        font-size: 60px;
    }
    
    .sectionHeaders {
        font-size: 24px;
        text-align: left;
    }

    .headerNotLinked {
        font-size: 24px;
    }

    .introText {
        font-size: 16px;
    }
}

@media (min-width: 769px) {
    .section {
        margin-top: 80px;
    }
}

@media (max-width: 768px) {
    .homePageHeader {
        text-align: center;
    }

    .linkContainer {
        text-align: center;
    }

    .introText {
        text-align: center;
    }

    .sectionIcons {
        margin: 20px 0;
    }

    .section {
        margin-top: 40px;
    }
}

@media (max-width: 660px) {
    .homePageHeader {
        font-size: 30px;
        text-align: center;
    }
}

.homePageHeader {
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    font-weight: bold;
    margin: 40px 0;
}

.homepageImg {
    margin: 40px 0;
}

.sectionIcons {
    font-size: 120px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: lavender;
}



.sectionHeaders {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
}

.sectionHeaders:hover {
    background-color: white;
    color: black;
    text-decoration: none;
}

.introText {
    font-family: 'Raleway', sans-serif;
    /* color: lavender; */
    padding-top: 20px;
}

.headerNotLinked {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.techPics {
    height: 50px;
    width: auto;
    display: block;
    margin: auto;
}

.picsSection {
    width: auto;
    background-color: white;
    margin: 15px;
}

.techName {
    color: black;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 22px;
}

.cPicLink {
    color: black;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 22px;
}

.cPicLink:hover {
    font-weight: bolder;
    text-decoration: none;
    color: black;
}

.image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: black;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.image__overlay--blur {
    backdrop-filter: blur(5px);
}

.image__overlay--primary {
    background: #FFFFFF;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.image__overlay:hover {
    opacity: 1;
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.linkContainer {
    width: 100%;
}