@media (min-width: 1201px) {
    .servicesHeader {
        font-size: 100px;
    }

    .titleHeader {
        font-size: 50px;
    }

    .servicesText {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .servicesHeader {
        font-size: 90px;
    }

    .titleHeader {
        font-size: 50px;
    }

    .servicesText {
        font-size: 18px;
    }
}

@media (max-width: 1000px) {
    .servicesHeader {
        font-size: 48px;
    }

    .titleHeader {
        font-size: 24px;
    }

    .servicesText {
        font-size: 16px;
    }
}

@media (min-width: 601px) {
    .servicesRows {
        margin-top: 100px;
    }
}

@media (max-width: 600px) {
    .titleHeader {
        font-size: 24px;
    }

    .servicesText {
        font-size: 16px;
        text-align: center;
    }

    .servicesRows {
        margin-top: 30px;
    }
}


.servicesHeader {
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
}

.iconsStyle {
    font-size: 150px;
    text-align: center;
    color: lavender;
}

.titleHeader {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    padding-bottom: 15px;
}

.servicesText {
    font-family: 'Raleway', sans-serif;
    color: #151515;
}

ul {
    list-style: none;
    padding: 0;
  }