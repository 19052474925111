html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}



.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.wrapper {
    flex: 1;
}

#logo {
    height: 100px;
    width: auto;
}