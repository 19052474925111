@media (min-width: 1201px) {
    .projectsHeader {
        font-size: 100px;
    }

    .productTitle {
        font-size: 36px;
    }

    .productDescription {
        font-size: 18px;
    }

    .projectsImage {
        height: auto;
        width: 350px;
        margin: auto;
    }
}

@media (max-width: 1200px) {
    .projectsHeader {
        font-size: 60px;
    }

    .productTitle {
        font-size: 36px;
        text-align: center;
    }

    .productDescription {
        font-size: 18px;
        text-align: center;
    }

    .projectsImage {
        height: auto;
        width: 250px;
        margin: auto;
    }
}

@media (max-width: 1000px) {
    .projectsHeader {
        font-size: 48px;
    }

    .projectsImage {
        height: auto;
        width: 250px;
        margin: auto;
        margin-bottom: 50px;
    }
}

@media (max-width: 600px) {

    .projectsHeader {
        font-size: 48px;
    }

    .projectsImage {
        height: auto;
        width: 70%;
        margin-bottom: 50px;
    }
}


.projectsHeader {
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    font-weight: bold;
    margin: 40px 0;
    text-align: center;
}

.product {
    padding-top: 50px;
    padding-bottom: 50px;
}

.productTitle {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #fbbc33;
    padding-bottom: 25px;
}

.productDescription {
    font-family: 'Raleway', sans-serif;
    color: #151515;
}
