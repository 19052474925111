.footerStyle {
    background-color: #151515;
    height: 100%;
    font-family: 'Raleway' sans-serif;
    color: white;
    padding-top: 50px;
    margin-top: 100px;
}

.copywrite {
    text-align: center;
    color: white;
    text-transform: none;
    font-family: 'Raleway' sans-serif;
}

.iconsSocial {
    font-size: 30px;
    padding-right: 10px;
    color: white;
}

hr {
    width: 70%;
    color: white;
}

.footerHeader {
    text-transform: uppercase;
    margin-bottom: 16px;
    color: #fbbc33;
}

.footerLinks {
    color: white;
}

.footerLinks:hover {
    text-decoration: none;
    background-color: white;
    color: black;
}

/* .navbar-light .navbar-nav .nav-link {
    color: white;   
} */

@media (max-width: 500px) {
    .copywrite {
        font-size: 12px;
    }
}

@media (min-width: 501px) {
    .copywrite {
        font-size: 16px;
    }
}